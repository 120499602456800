<template>
  <message
    :tab="tab"
    :message.sync="computedMessage"
    :load-message="loadMessage"
    :save="saveMessage"
    invoice-document-template
    v-on="$listeners"
  />
</template>

<script>

export default {
  components: {
    Message: () => import('@/pages/messages/message')
  },
  data () {
    return {
      dataMessage: {}
    }
  },
  computed: {
    computedMessage: {
      get () {
        return this.dataMessage
      },
      set (v) {
        this.dataMessage = v
      }
    }
  },
  methods: {
    async loadMessage () {
      const m = {
        message: {
          accountId: this.$route.params.id
        },
        recipients: [],
        attachments: [],
        invoices: '',
        subject: '',
        content: '',
        header: '',
        footer: ''
      }

      if (Array.isArray(this.tab.invoiceIds)) {
        m.attachments = (await this.$http().post('/message/getAttachments', { invoiceIds: this.tab.invoiceIds })).data.attachments
        m.invoices = this.tab.invoiceIds.join()
      }

      this.computedMessage = m
    },
    async saveMessage (code) {
      return this.$http().post('/message', this.computedMessage)
    }
  },
  props: {
    tab: Object
  }
}
</script>
